export const checkChannelActivation = async (channelUrl) => {
    const inboxBaseUrl = process.env.REACT_APP_INBOX_BASE_URL;
    const url = `${inboxBaseUrl}/chat/booking-status?channelUrl=${channelUrl}`;

    try {
        const response = await fetch(url);

        if (response) {
            return await response.json();
        }

        return null;
    } catch (error) {
        throw new Error('Error checking channel: ' + error.message);
    }
};