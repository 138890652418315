import React, { useEffect, useState } from 'react';
import '@sendbird/uikit-react/dist/index.css';
import SendbirdChat from '@sendbird/chat';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import CustomGroupChannel from './CustomGroupChannel';
import { checkChannelActivation } from '../services/SendbirdUserService';

export default function SnippetComponent() {

  const [channelUrl, setChannelUrl] = useState(null);
  const appId = process.env.REACT_APP_SENDBIRD_APP_ID;
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const userIdQuery = queryParams.get('userId');
  const channelUrlQuery = queryParams.get('channelUrl');
  const [chatActivated, setChatActivated] = useState(1);

  useEffect(() => {
    async function createConnection() {
      if (!userIdQuery || !channelUrlQuery || !appId) {
        console.error('Missing appId, userId or channelUrl');
        return;
      }
      const params = {
        appId: appId,
        modules: [
          new GroupChannelModule()
        ],
      };
      const sb = SendbirdChat.init(params);
      try {
        await sb.connect(userIdQuery);
      
        const channel = await sb.groupChannel.getChannel(channelUrlQuery);
            if (channel) {
              setChannelUrl(channelUrlQuery);
              const status = await checkChannelActivation(channel.url);
              setChatActivated(status);
       
            }
      } catch (error) {
        console.error('Sendbird connection error:', error);
        return;
      }
    }
    createConnection();
  }, [appId, userIdQuery, channelUrlQuery]);

  if (!channelUrl) {
    return <div>Loading chat...</div>;
  }

  return (
    <SendbirdProvider
      appId={appId}
      userId={userIdQuery}
    >
      <CustomGroupChannel
        channelUrl={channelUrl}
        userNickname={''}
        chatStatus={chatActivated}
        showHeader={false}
      />
    </SendbirdProvider>
  );
}
