import { React, useEffect, useState } from "react";
import '../customChannelPreview.css';

const CustomChannelPreview = ({ channel, unreadActivated, selectedChannelUrl, getChannelNickname, userId }) => {
    const isSelected = selectedChannelUrl === channel._url;
    const dateLastMessage = new Date(channel.lastMessage.createdAt);
    const [nickname, setNickname] = useState('');
    const [bookingId, setBookingId] = useState(null);

    useEffect(() => {
        async function getBookingId()
        {
            if (channel.cachedMetaData.booking_id) {
                setBookingId(channel.cachedMetaData.booking_id);
                return;
            }

            const metadata = await channel.getMetaData(['booking_id']);

            if (metadata.booking_id) {
                setBookingId(metadata.booking_id);
            }
        }

        getChannelNickname(channel, userId, setNickname);
        getBookingId();
    }, []);

    function isBeforeToday(dateLastMessage, today) {
        dateLastMessage.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        return dateLastMessage < today;
    }

    const getDate = () => {
        const formattedDate = new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false
        }).formatToParts(dateLastMessage);

        const dateParts = formattedDate.reduce((parts, { type, value }) => {
            parts[type] = value;
            return parts;
        }, {});

        let messageDate = `${dateParts.hour}:${dateParts.minute}`;

        if (isBeforeToday(dateLastMessage, new Date())) {
            messageDate = `${dateParts.day} ${dateParts.month}, ${messageDate}`;
        }

        return messageDate;
    };

    return (
        <div
            className={isSelected ? "menuItemSelected" : "menuItem"}
            style={{display: unreadActivated && !channel.unreadMessageCount ? "none" : "block"}}
        >
            <h4>
                {nickname ? nickname : ""}

                <span className="bookingId">{bookingId ?
                        <a
                            href={process.env.REACT_APP_INBOX_BASE_URL + "/booking/view/" + bookingId}
                            target="_blank"
                            rel="noreferrer"
                            className="bookingLink">{bookingId}</a> :
                        ''
                }</span>

                <span className="bookingDate">{getDate()}</span>
            </h4>

            {channel.unreadMessageCount ? <span className="unreadMessageCount">{channel.unreadMessageCount}</span> : ''}

            <p className="lastMessage">{channel.lastMessage?.message || "No messages yet"}</p>
        </div>
    );
};

export default CustomChannelPreview;