import React, { useState } from 'react';

const Button = ({ children, isActive, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const buttonStyle = {
    background: isHovered ? '#F1F2F4' : '#FFFFFF',
    fontSize: '14px',
    color: '#121417',
    padding: '10px 16px',
    marginLeft: '20px',
    fontWeight: isActive ? '700' : '400',
    borderRadius: '20px',
    border: '2px solid',
    borderColor: isActive ? '#121417' : '#DDDFE4',
    transition: 'background 0.3s',
    cursor: 'pointer',
    display: 'inline-flex'
  };

  return (
    <button
      style={buttonStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;